import React,{ useState, useEffect } from 'react';
import CardInfo from './CardInfo';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ethers } from 'ethers'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Staking from '../../artifacts/contracts/Staking.sol/Staking.json';
import Staking18 from '../../artifacts/contracts/Staking18.sol/Staking18.json';
import Staking24 from '../../artifacts/contracts/Staking24.sol/Staking24.json';
import RedMoney from '../../artifacts/contracts/RedMoney.sol/RedMoney.json';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ERC20Token = require("../../components/ERC20/ERC20Token");
const { web3, applyDecimals } = require("../../utils/ethereumAPI");
const moneyFormat = (value, money) => {
  let options = money ? { style: 'currency', currency: 'USD'} : {minimumFractionDigits: 2};
  return new Intl.NumberFormat('en-US', options).format(value);
}

/* TAB FUNCTIONS */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


/* END TAB FUNCTIONS */



function createData(id, amount, end, active, actions) {
  return {id, amount, end, active, actions};
}

const Inversion = ({walletAccount, tokenData, tokenAddress, stakingAddress, stakingAddress18, stakingAddress24}) => {

  const [staking, setStakingValue] = useState(0)
  const [allowance, setAllowanceValue] = useState(0);
  const [reward, setRewardValue] = useState(0)
  const [balance, setBalanceValue] = useState(0)
  const [totalStakedContract, setTotalStakedContractValue] = useState(0)
  const [totalEarned, setTotalEarnedValue] = useState(0)
  const [activeContractType, setActiveContractType] = useState("12");
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeAPR, setActiveAPR] = useState("12");
  const [canUnStakeAll, setCanUnStakeAll] = useState(true);


  const [stakingHistory, setStakingHistory] = useState([]);
  const [value, setValue] = React.useState(0);

  const [tokenData2, setTokenData2] = useState(tokenData);


  /* modal stake */
  const [openStake, setOpenStake] = React.useState(false);
  const handleClickOpenStake = () => {
    setOpenStake(true);
  };
  const handleCloseStake = () => {
    setOpenStake(false);
  };

  /* modal claim */
  const [openClaim, setOpenClaim] = React.useState(false);
  const handleClickOpenClaim = () => {
    setOpenClaim(true);
  };
  const handleCloseClaim = () => {
    setOpenClaim(false);
  };

  /* modal unstake */
  const [openUnStake, setOpenUnStake] = React.useState(false);
  const handleClickOpenUnStake = () => {
    setOpenUnStake(true);
  };
  const handleCloseUnStake = () => {
    setOpenUnStake(false);
  };

  useEffect(() => {
    async function fetchData(){
        const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
        const name = await web3Token.methods.name().call();
        const symbol = await web3Token.methods.symbol().call();
        const totalSupply = await web3Token.methods.totalSupply().call();
        const decimals = await web3Token.methods.decimals().call();

        const accounts = await web3.eth.getAccounts();
        //setAccountAddress(accounts[0]);
        const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

        setTokenData2(tokenData2 => [
            tokenData2[0],
            { ...tokenData2[1], value: name},
            { ...tokenData2[2], value: symbol},
            { ...tokenData2[3], value: applyDecimals(totalSupply, decimals)},
            { ...tokenData2[4], value: decimals},
            { ...tokenData2[5], value: applyDecimals(currentBalance, decimals)},
        ])
    }
    fetchData();
}, [balance,tokenAddress]);

  /* TAB VARIABLES */

  updateValues();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 0){
      setActiveContractType("12");
      setActiveIndex(0);
      setActiveAPR("12");
    }else if(newValue === 1){
      setActiveContractType("18");
      setActiveIndex(1);
      setActiveAPR("24");
    }else{
      setActiveContractType("24");
      setActiveIndex(2);
      setActiveAPR("36");
    }
    //updateValues();
  };
  /* END TAB VARIABLES */

  // request access to the user's account. This works regardless of the wallet you're using.
  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  // call the smart contract, read the current greeting value
  async function getStaking() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }

      try {
        const data = await contract.connect(walletAccount).getStaking();

        let stakingLen = data[0].length;
        //creacion de los objetos
        let innerHistory = []
        var canUnStakeAll = true;
        for (let indexStaking = 0; indexStaking < stakingLen; indexStaking++) {
          let stakingObj = createData(
            indexStaking,
            data[0][indexStaking] / 1e18,
            new Date(data[1][indexStaking] * 1000)+"",
            data[2][indexStaking]?"true":"false",
            );
          if(data[2][indexStaking]){
            canUnStakeAll = canUnStakeAll && (new Date(data[1][indexStaking] * 1000) < new Date())
          }
          innerHistory.push(stakingObj);

        }
        setCanUnStakeAll(canUnStakeAll);

        setStakingHistory(innerHistory);

      } catch (err) {
        console.log("Error: ", err)
      }
    }
  }

  // call the smart contract, send an update
  async function setStaking() {
    handleCloseStake();
    if (!staking) return
    if (typeof window.ethereum !== 'undefined') {

      let stakingAmount = ethers.utils.parseEther(staking)
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
      }

      const transaction = await contract.stake(stakingAmount, {gasLimit: 2000000});
      await transaction.wait();
      setStakingValue("");
      updateValues();
    }
  }

  // call the smart contract, send an update
  async function claim() {
    handleCloseClaim();
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()
      //const contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
      //const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
      //await tokenR.approve(stakingAddress, staking)

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer);
      }

      const transaction = await contract.claimReward({gasLimit: 2000000});
      await transaction.wait()
      updateValues();
    }
  }

  async function getReward(){
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }
      try {
        const earned = await contract.earned(walletAccount)
        //const paid = await contract.s_rewards(walletAccount)
        //let rewards = (earned - paid) / 1e18
        let rewards = earned / 1e18
        setRewardValue(rewards);
        //console.log('data reward: ', rewards)
      } catch (err) {
        console.log("Error: ", err)
      }
    }
  }

  async function getAllowance() {
    if (typeof window.ethereum !== 'undefined') {
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()

      let contract;
      let data;
      //try{
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        data = await tokenR.connect(signer).allowance(signer.getAddress(), stakingAddress)
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        data = await tokenR.allowance(signer.getAddress(), stakingAddress18);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        data = await tokenR.allowance(signer.getAddress(), stakingAddress24);
      }

      setAllowanceValue(data / 1e18);


    }
  }

   // call the smart contract, send an update
   async function setAllowance() {
    if (!staking) return
    if (typeof window.ethereum !== 'undefined') {
      let stakingAmount = ethers.utils.parseEther(staking)
      await requestAccount()
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner()

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        await tokenR.connect(signer).approve(stakingAddress, stakingAmount,{gasLimit: 2000000})
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        await tokenR.connect(signer).approve(stakingAddress18, stakingAmount, {gasLimit: 2000000})
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)
        await tokenR.connect(signer).approve(stakingAddress24, stakingAmount, {gasLimit: 2000000})
      }

      updateValues();
    }
  }



  async function getBalance(){
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum)

      let contract;
      if(activeContractType==="12"){
        contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
      }else if(activeContractType==="18"){
        contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
      }else{
        contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
      }

      try {
        const data = await contract.connect(walletAccount).getBalance();
        let balance = data / 1e18
        setBalanceValue(balance);
        //console.log('data balance: ', balance)
      } catch (err) {
        console.log("Error: ", err)
      }
    }
  }

    // call the smart contract, send an update
    async function unStake(id) {
      handleCloseUnStake();
      //console.log(id);
      if (typeof window.ethereum !== 'undefined') {
        //boton true
        await requestAccount()
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        //const contract = new ethers.Contract(stakingAddress, Staking.abi, signer)
        const tokenR = new ethers.Contract(tokenAddress, RedMoney.abi, signer)

        let contract;
        if(activeContractType==="12"){
          contract = new ethers.Contract(stakingAddress, Staking.abi, signer);
        }else if(activeContractType==="18"){
          contract = new ethers.Contract(stakingAddress18, Staking18.abi, signer);
        }else{
          contract = new ethers.Contract(stakingAddress24, Staking24.abi, signer);
        }

        const transaction = await contract.withdraw(id)
        await transaction.wait()
        updateValues();
      }
    }


    async function getTotalStakedContract(){
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let contract;
        if(activeContractType==="12"){
          contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
        }else if(activeContractType==="18"){
          contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
        }else{
          contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
        }
        try {
          const data = await contract.s_totalSupply();
          let balance = data / 1e18
          setTotalStakedContractValue(balance);
          //console.log('data balance: ', balance)
        } catch (err) {
          console.log("Error: ", err)
        }
      }
    }

    async function getTotalEarned(){
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        //const contract = new ethers.Contract(stakingAddress, Staking.abi, provider)
        let contract;
        if(activeContractType==="12"){
          contract = new ethers.Contract(stakingAddress, Staking.abi, provider);
        }else if(activeContractType==="18"){
          contract = new ethers.Contract(stakingAddress18, Staking18.abi, provider);
        }else{
          contract = new ethers.Contract(stakingAddress24, Staking24.abi, provider);
        }
        try {
          const data = await contract.earned(walletAccount);
          let balance = data / 1e18
          setTotalEarnedValue(balance);
          //console.log('data balance: ', balance)
        } catch (err) {
          console.log("Error: ", err)
        }
      }
    }

    function updateValues(){
      getStaking();
      getBalance();
      getReward();
      getTotalStakedContract();
      getAllowance();
    }

  return (
    <>
      <CardInfo walletAccount={walletAccount} tokenData={tokenData2} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>
      <Box sx={{ width: '100%' }}>
      <h5>Please select a period of Staking:</h5>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="RED12" {...a11yProps(0)} />
          <Tab label="RED18" {...a11yProps(1)} />
          <Tab label="RED24" {...a11yProps(2)} />
        </Tabs>
      </Box>
      {/** tabs */}
      <TabPanel value={value} index={0}>
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} ><b>12 Month Staking Contract for RED</b><br/>

              APR: 12%</p>
              </Grid>

              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 6,mt: 1 }} >
          <Grid container>
            <Grid item xs={5} >
              <Grid container spacing={2} >
                <Grid item xs={12} >
                  <FormControl>
                    <InputLabel htmlFor="my-input">Qty</InputLabel>
                    <Input value={staking} onChange={e => setStakingValue(e.target.value)} id="my-input" aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {/*******MODAL */}
                    <div>
                      {/*<Button variant="outlined" onClick={handleClickOpen}>
                        Open alert dialog
                      </Button>*/}
                      <Button variant="contained" onClick={handleClickOpenStake}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                          stake
                      </Button>
                      <Dialog
                        open={openStake}
                        onClose={handleCloseStake}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Realizar Stake"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <p>Para poder hacer un Staking primero deberá otorgar permisos al contrato para tomar el RED MONEY de su cuenta.</p>
                            <p>
                              <b>Allowance aprobado:</b> {moneyFormat(allowance)} <br/>
                              <b>Allowance requerido:</b> {moneyFormat(staking)}
                            </p>

                          {staking < 100 ?
                            <p>La cantidad para el stake/allowance aprobado debe ser mayor o igual a 100</p>:
                            <p>¿Desea realizar un stake por {moneyFormat(staking)} RED?</p>
                          }

                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseStake}>Cancelar</Button>
                          <Button disabled={staking < 100 || (staking <= allowance) ? true: false} onClick={setAllowance} autoFocus>
                            Aprobar
                          </Button>
                          <Button disabled={staking < 100 || (allowance < staking) ? true: false} onClick={setStaking} autoFocus>
                            Staking
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    {/*** fin modal */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={7} ms={2}>
                <Grid container spacing={2} >
                  <Grid item xs={12} >
                    <FormControl>
                      <InputLabel htmlFor="my-input" >RED Ganado:</InputLabel>
                      <Input id="my-input" value={moneyFormat(reward)} aria-describedby="my-helper-text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {canUnStakeAll ?
                      <Button variant="contained" disabled={true} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#ccc' }} >
                        Claim Reward
                      </Button>
                      :
                      <div>
                          {/*<Button variant="contained" onClick={claim} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                              Claim Reward
                          </Button>*/}
                        <Button variant="contained" onClick={handleClickOpenClaim}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                            Claim Reward
                        </Button>
                        <Dialog
                          open={openClaim}
                          onClose={handleCloseClaim}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Realizar Claim"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {moneyFormat(reward) === 0 ?
                              "La cantidad para el claim debe ser mayor a 0":
                              "¿Desea realizar un claim por "+moneyFormat(reward)+" RED?"
                            }

                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseClaim}>Cancelar</Button>
                            <Button disabled={moneyFormat(reward) === 0 ? true: false} onClick={claim} autoFocus>
                              Continuar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>


                    }

                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">End</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {stakingHistory.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{moneyFormat(row.amount)}</TableCell>
                      <TableCell align="right">{row.end}</TableCell>
                      <TableCell align="right">
                      { row.active==="true" ?
                          (new Date(row.end) < new Date() ?
                            <div>
                                {/*<Button variant="contained" onClick={() => unStake(row.id)} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >UnStake</Button>*/}
                              <Button variant="contained" onClick={handleClickOpenUnStake}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                                UnStake
                              </Button>
                              <Dialog
                                open={openUnStake}
                                onClose={handleCloseUnStake}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {"Realizar UnStake"}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    ¿Desea retirar {row.amount} RED del stake?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleCloseUnStake}>Cancelar</Button>
                                  <Button onClick={() => unStake(row.id)} autoFocus>
                                    Continuar
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                            :
                            <Button variant="contained" disabled={true} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#ccc' }} >UnStake</Button>)
                          :
                          "Unstaked" }

                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={12} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >Total Staked in contract {moneyFormat(totalStakedContract)} RED<br/></p>
              </Grid>
          </Grid>
        </Grid>
      </TabPanel>





      <TabPanel value={value} index={1}>
      <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} ><b>18 Month Staking Contract for RED</b><br/>
              APR: 24%</p>
              </Grid>

              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 6,mt: 1 }} >
          <Grid container>
            <Grid item xs={5} >
              <Grid container spacing={2} >
                <Grid item xs={12} >
                  <FormControl>
                    <InputLabel htmlFor="my-input">Qty</InputLabel>
                    <Input value={staking} onChange={e => setStakingValue(e.target.value)} id="my-input" aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {/*******MODAL */}
                    <div>
                      {/*<Button variant="outlined" onClick={handleClickOpen}>
                        Open alert dialog
                      </Button>*/}
                      <Button variant="contained" onClick={handleClickOpenStake}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                          stake
                      </Button>
                      <Dialog
                        open={openStake}
                        onClose={handleCloseStake}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Realizar Stake"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <p>Para poder hacer un Staking primero deberá otorgar permisos al contrato para tomar el RED MONEY de su cuenta.</p>
                            <p>
                              <b>Allowance aprobado:</b> {moneyFormat(allowance)} <br/>
                              <b>Allowance requerido:</b> {moneyFormat(staking)}
                            </p>

                          {staking < 100 ?
                            <p>La cantidad para el stake/allowance aprobado debe ser mayor o igual a 100</p>:
                            <p>¿Desea realizar un stake por {moneyFormat(staking)} RED?</p>
                          }

                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseStake}>Cancelar</Button>
                          <Button disabled={staking < 100 || (staking <= allowance) ? true: false} onClick={setAllowance} autoFocus>
                            Aprobar
                          </Button>
                          <Button disabled={staking < 100 || (allowance < staking) ? true: false} onClick={setStaking} autoFocus>
                            Staking
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    {/*** fin modal */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={7} ms={2}>
                <Grid container spacing={2} >
                  <Grid item xs={12} >
                    <FormControl>
                      <InputLabel htmlFor="my-input" >RED Ganado:</InputLabel>
                      <Input id="my-input" value={moneyFormat(reward)} aria-describedby="my-helper-text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                  {canUnStakeAll ?
                      <Button variant="contained" disabled={true} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#ccc' }} >
                        Claim Reward
                      </Button>
                      :
                      <div>
                          {/*<Button variant="contained" onClick={claim} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                              Claim Reward
                          </Button>*/}
                        <Button variant="contained" onClick={handleClickOpenClaim}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                            Claim Reward
                        </Button>
                        <Dialog
                          open={openClaim}
                          onClose={handleCloseClaim}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Realizar Claim"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {moneyFormat(reward) === 0 ?
                              "La cantidad para el claim debe ser mayor a 0":
                              "¿Desea realizar un claim por "+moneyFormat(reward)+" RED?"
                            }

                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseClaim}>Cancelar</Button>
                            <Button disabled={moneyFormat(reward) === 0 ? true: false} onClick={claim} autoFocus>
                              Continuar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    }
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">End</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {stakingHistory.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{moneyFormat(row.amount)}</TableCell>
                      <TableCell align="right">{row.end}</TableCell>
                      <TableCell align="right">
                      { row.active==="true" ?
                          (new Date(row.end) < new Date() ?
                            <div>
                                {/*<Button variant="contained" onClick={() => unStake(row.id)} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >UnStake</Button>*/}
                              <Button variant="contained" onClick={handleClickOpenUnStake}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                                UnStake
                              </Button>
                              <Dialog
                                open={openUnStake}
                                onClose={handleCloseUnStake}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {"Realizar UnStake"}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    ¿Desea retirar {row.amount} RED del stake?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleCloseUnStake}>Cancelar</Button>
                                  <Button onClick={() => unStake(row.id)} autoFocus>
                                    Continuar
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>:
                            <Button variant="contained" disabled={true} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#ccc' }} >UnStake</Button>)
                          :
                          "Unstaked" }
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >Total Staked in contract {moneyFormat(totalStakedContract)} RED<br/></p>
              </Grid>
              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
      </TabPanel>


      <TabPanel value={value} index={2}>
      <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} ><b>24 Month Staking Contract for RED</b><br/>
              APR: 36%</p>
              </Grid>

              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 6,mt: 1 }} >
          <Grid container>
            <Grid item xs={5} >
              <Grid container spacing={2} >
                <Grid item xs={12} >
                  <FormControl>
                    <InputLabel htmlFor="my-input">Qty</InputLabel>
                    <Input value={staking} onChange={e => setStakingValue(e.target.value)} id="my-input" aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {/*******MODAL */}
                    <div>
                      {/*<Button variant="outlined" onClick={handleClickOpen}>
                        Open alert dialog
                      </Button>*/}
                      <Button variant="contained" onClick={handleClickOpenStake}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                          stake
                      </Button>
                      <Dialog
                        open={openStake}
                        onClose={handleCloseStake}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Realizar Stake"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <p>Para poder hacer un Staking primero deberá otorgar permisos al contrato para tomar el RED MONEY de su cuenta.</p>
                            <p>
                              <b>Allowance aprobado:</b> {moneyFormat(allowance)} <br/>
                              <b>Allowance requerido:</b> {moneyFormat(staking)}
                            </p>

                          {staking < 100 ?
                            <p>La cantidad para el stake/allowance aprobado debe ser mayor o igual a 100</p>:
                            <p>¿Desea realizar un stake por {moneyFormat(staking)} RED?</p>
                          }

                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseStake}>Cancelar</Button>
                          <Button disabled={staking < 100 || (staking <= allowance) ? true: false} onClick={setAllowance} autoFocus>
                            Aprobar
                          </Button>
                          <Button disabled={staking < 100 || (allowance < staking) ? true: false} onClick={setStaking} autoFocus>
                            Staking
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    {/*** fin modal */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={7} ms={2}>
                <Grid container spacing={2} >
                  <Grid item xs={12} >
                    <FormControl>
                      <InputLabel htmlFor="my-input" >RED Ganado:</InputLabel>
                      <Input id="my-input" value={moneyFormat(reward)} aria-describedby="my-helper-text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                  {canUnStakeAll ?
                      <Button variant="contained" disabled={true} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#ccc' }} >
                        Claim Reward
                      </Button>
                      :
                      <div>
                          {/*<Button variant="contained" onClick={claim} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                              Claim Reward
                          </Button>*/}
                        <Button variant="contained" onClick={handleClickOpenClaim}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                            Claim Reward
                        </Button>
                        <Dialog
                          open={openClaim}
                          onClose={handleCloseClaim}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Realizar Claim"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {moneyFormat(reward) === 0 ?
                              "La cantidad para el claim debe ser mayor a 0":
                              "¿Desea realizar un claim por "+moneyFormat(reward)+" RED?"
                            }

                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseClaim}>Cancelar</Button>
                            <Button disabled={moneyFormat(reward) === 0 ? true: false} onClick={claim} autoFocus>
                              Continuar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    }
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">End</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {stakingHistory.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{moneyFormat(row.amount)}</TableCell>
                      <TableCell align="right">{row.end}</TableCell>
                      <TableCell align="right">
                      { row.active==="true" ?
                          (new Date(row.end) < new Date() ?
                            <div>
                                {/*<Button variant="contained" onClick={() => unStake(row.id)} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >UnStake</Button>*/}
                              <Button variant="contained" onClick={handleClickOpenUnStake}  style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#7f0215' }} >
                                UnStake
                              </Button>
                              <Dialog
                                open={openUnStake}
                                onClose={handleCloseUnStake}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {"Realizar UnStake"}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    ¿Desea retirar {row.amount} RED del stake?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleCloseUnStake}>Cancelar</Button>
                                  <Button onClick={() => unStake(row.id)} autoFocus>
                                    Continuar
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>:
                            <Button variant="contained" disabled={true} style={{ color: '#FFFFFF', borderWidth: '0px', fontFamily: '', backgroundColor: '#ccc' }} >UnStake</Button>)
                          :
                          "Unstaked" }
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 1,mt: 1 }} >
          <Grid container>
              <Grid item xs={7} >
              <p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >Total Staked in contract {moneyFormat(totalStakedContract)} RED<br/></p>
              </Grid>
              <Grid item xs={5}>
              {/*<p style={{textAlign: "left"}} sx={{mb:0,mt:0}} >
              Total Staked: {balance} RED<br/>
              </p>*/}
              </Grid>
          </Grid>
        </Grid>
      </TabPanel>

      {/** end tabs */}

    </Box>

    </>
  )
}
export default Inversion
