import React,{ useState, useEffect }  from 'react'
import { Box } from '@mui/material';
import CardInfo from './CardInfo';
import PcPng from '../../assets/img/pc-remoney.png';

const ERC20Token = require("../../components/ERC20/ERC20Token");
const { web3, applyDecimals } = require("../../utils/ethereumAPI");

const MyWallet = ({walletAccount, tokenData, tokenAddress, stakingAddress, stakingAddress18, stakingAddress24}) => {

  const [tokenData2, setTokenData2] = useState(tokenData);

  useEffect(() => {
    async function fetchData(){
        const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
        const name = await web3Token.methods.name().call();
        const symbol = await web3Token.methods.symbol().call();
        const totalSupply = await web3Token.methods.totalSupply().call();
        const decimals = await web3Token.methods.decimals().call();

        const accounts = await web3.eth.getAccounts();
        //setAccountAddress(accounts[0]);
        const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

        setTokenData2(tokenData2 => [
            tokenData2[0],
            { ...tokenData2[1], value: name},
            { ...tokenData2[2], value: symbol},
            { ...tokenData2[3], value: applyDecimals(totalSupply, decimals)},
            { ...tokenData2[4], value: decimals},
            { ...tokenData2[5], value: applyDecimals(currentBalance, decimals)},
        ])
    }
    fetchData();
}, [tokenAddress]);

  return (
    <Box>
      <CardInfo walletAccount={walletAccount} tokenData={tokenData2} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>
      <Box style={{position: 'relative'}}>
        <img src={PcPng} alt="Pc" style={{marginTop: 60, maxWidth: '100%',}} />
      </Box>
    </Box>
  )
}

export default MyWallet