import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';

export default function HeaderStatic({title}) {
  return (
    <Box sx={{ pb: 7 }} >
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0 }} elevation={3}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, my:1}} >{title}</Typography>
        </Paper>
    </Box>
  );
}