import React,{ useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {CssBaseline} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import "@fontsource/poppins";
import HeaderStatic from './Fragments/HeaderStatic';
import EnviarToken from './Auth/EnviarToken';
import RecibirToken from './Auth/RecibirToken';
import Inversion from './Auth/Inversion';
import MyWallet from './Auth/MyWallet';

const ERC20Token = require("../components/ERC20/ERC20Token");
const { web3, applyDecimals } = require("../utils/ethereumAPI");

const AppAuth2 = ({walletAccount, tokenAddress, stakingAddress,stakingAddress18,stakingAddress24}) => {
  const [view, setView] = useState(0);
  const [title, setTitle] = useState("My Wallet");
  const ref = React.useRef(null);

  const [tokenData, setTokenData] = useState([
    { id: 0, name: 'TokenAddress', value: tokenAddress},
    { id: 1, name: 'Name', value: ''},
    { id: 2, name: 'Symbol', value: ''},
    { id: 3, name: 'TotalSupply', value: ''},
    { id: 4, name: 'Decimals', value: ''},
    { id: 5, name: 'Currentbalance', value: ''}
  ]);

  const [accountAddress, setAccountAddress] = useState("");

  useEffect(() => {
      async function fetchData(){
          const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
          const name = await web3Token.methods.name().call();
          const symbol = await web3Token.methods.symbol().call();
          const totalSupply = await web3Token.methods.totalSupply().call();
          const decimals = await web3Token.methods.decimals().call();

          const accounts = await web3.eth.getAccounts();
          setAccountAddress(accounts[0]);
          const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

          setTokenData(tokenData => [
              tokenData[0],
              { ...tokenData[1], value: name},
              { ...tokenData[2], value: symbol},
              { ...tokenData[3], value: applyDecimals(totalSupply, decimals)},
              { ...tokenData[4], value: decimals},
              { ...tokenData[5], value: applyDecimals(currentBalance, decimals)},
          ])
      }
      fetchData();
  }, [tokenAddress]);

  return (
    <Container component="main" maxWidth="sm" sx={{ height:`calc(100%}px)`}} >
      <CssBaseline />
      <HeaderStatic title={title}/>
      <Grid container >
          <Grid item xs={12} >
            <Box sx={{ display: 'flex',flexDirection: 'column',alignItems: 'center',marginTop: 4,paddingTop: 0}} >
              
            </Box>
            { view === 0 ?
              <MyWallet walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>: 
              view === 1 ? <RecibirToken walletAccount={accountAddress} tokenData={tokenData} tokenAddress={tokenAddress}/>:
              view === 2 ? <EnviarToken walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/> :
              <Inversion walletAccount={walletAccount} tokenData={tokenData} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/> 
            }
          </Grid>
      </Grid>
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={view}
            onChange={(event, newValue) => {
              setView(newValue);
            }}
          >
            <BottomNavigationAction label="Wallet" icon={
              /* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
              <span style={{display: 'block', width: '1.25em', height: '1em', fontSize: 16, marginBottom: 6 }}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentColor'}} viewBox="0 0 512 512"><path d="M448 32C465.7 32 480 46.33 480 64C480 81.67 465.7 96 448 96H80C71.16 96 64 103.2 64 112C64 120.8 71.16 128 80 128H448C483.3 128 512 156.7 512 192V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H448zM416 336C433.7 336 448 321.7 448 304C448 286.3 433.7 272 416 272C398.3 272 384 286.3 384 304C384 321.7 398.3 336 416 336z"/></svg>
              </span>
            } onClick={(e) => setTitle("My Wallet")} />
            <BottomNavigationAction label="Recibir" icon={
              /* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
              <span style={{display: 'block', width: '1.25em', height: '1em', fontSize: 16, marginBottom: 6 }}>                
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentColor'}} viewBox="0 0 512 512"><path d="M447 56.25C443.5 42 430.7 31.1 416 31.1H96c-14.69 0-27.47 10-31.03 24.25L3.715 304.9C1.247 314.9 0 325.2 0 335.5v96.47c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-96.47c0-10.32-1.247-20.6-3.715-30.61L447 56.25zM352 352H160L128 288H72.97L121 96h270l48.03 192H384L352 352z"/></svg>
              </span>
            } onClick={(e) => setTitle("Receive")}/>
            <BottomNavigationAction label="Enviar" icon={
              /* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
              <span style={{display: 'block', width: '1.25em', height: '1em', fontSize: 16, marginBottom: 6 }}>                
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentColor'}} viewBox="0 0 512 512"><path d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"/></svg>
              </span>
            } onClick={(e) => setTitle("Send Tokens")}/>
            <BottomNavigationAction label="Staking" icon={
              /* Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */
              <span style={{display: 'block', width: '1.25em', height: '1em', fontSize: 16, marginBottom: 6 }}>                
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentColor'}} viewBox="0 0 576 512"><path d="M144 240C144 195.8 179.8 160 224 160C268.2 160 304 195.8 304 240C304 284.2 268.2 320 224 320C179.8 320 144 284.2 144 240zM512 0C547.3 0 576 28.65 576 64V416C576 451.3 547.3 480 512 480H496L480 512H416L400 480H176L160 512H96L80 480H64C28.65 480 0 451.3 0 416V64C0 28.65 28.65 0 64 0H512zM224 400C312.4 400 384 328.4 384 240C384 151.6 312.4 80 224 80C135.6 80 64 151.6 64 240C64 328.4 135.6 400 224 400zM480 221.3C498.6 214.7 512 196.9 512 176C512 149.5 490.5 128 464 128C437.5 128 416 149.5 416 176C416 196.9 429.4 214.7 448 221.3V336C448 344.8 455.2 352 464 352C472.8 352 480 344.8 480 336V221.3z"/></svg>
              </span>
            } onClick={(e) => setTitle("Staking")}/>
            
          </BottomNavigation>
        </Paper>
      </Box>
    </Container>
  );


}


export default AppAuth2
