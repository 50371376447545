import React, {useState, useEffect} from 'react'
import { IconButton, Tooltip, Typography } from '@mui/material';
import QRCode from "qrcode";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ERC20Token = require("../../components/ERC20/ERC20Token");
const { web3, applyDecimals } = require("../../utils/ethereumAPI");

const RecibirToken = ({walletAccount, tokenData, tokenAddress}) => {

  const [tokenData2, setTokenData2] = useState(tokenData);

  useEffect(() => {
    async function fetchData(){
        const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
        const name = await web3Token.methods.name().call();
        const symbol = await web3Token.methods.symbol().call();
        const totalSupply = await web3Token.methods.totalSupply().call();
        const decimals = await web3Token.methods.decimals().call();

        const accounts = await web3.eth.getAccounts();
        //setAccountAddress(accounts[0]);
        const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

        setTokenData2(tokenData2 => [
            tokenData2[0],
            { ...tokenData2[1], value: name},
            { ...tokenData2[2], value: symbol},
            { ...tokenData2[3], value: applyDecimals(totalSupply, decimals)},
            { ...tokenData2[4], value: decimals},
            { ...tokenData2[5], value: applyDecimals(currentBalance, decimals)},
        ])
    }
    fetchData();
}, [tokenAddress]);

  const currentBalance = tokenData2.find(x => x.name === "Currentbalance").value;
  const symbolToken = tokenData.find(x => x.name === "Symbol").value;

  const generateQrCode = async(walletAccount) => {
    try{
      // console.log(walletAccount);
      const response = await QRCode.toDataURL(walletAccount);
      setImageUrl(response);
    }catch(error){
      console.log(error);
    }
  }

  const [imageUrl, setImageUrl] = useState(generateQrCode(walletAccount));

  //const [state, setState] = useState(false);

  const moneyFormat = (value, money) => {
    let options = money ? { style: 'currency', currency: 'USD'} : {minimumFractionDigits: 2};
    return new Intl.NumberFormat('en-US', options).format(value);
  }

  return (
    <div>
        <Typography variant='h6' sx={{mt:4, fontWeight:"bold"}}>Scan your QR code</Typography>
        {/*<QRCodeSVG value={walletAccount} size="250"/>*/}
        <a href={imageUrl} download> <img src={imageUrl} alt={"address:"+walletAccount} width="300"/></a>
        <br/>
        
          <CopyToClipboard text={walletAccount}
            /*onCopy={() => setState(true)}*/>
            <Typography sx={{fontSize: 10 }}>WALLET: {walletAccount} <Tooltip title="Copiar">
            <IconButton sx={{fontSize: 10 }} >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip></Typography>
          </CopyToClipboard>
        
        <p style={{
          fontSize:16,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1,
          fontWeight: 800
        }}>
          <span style={{opacity: 0.5, fontSize: '0.85em'}}>BALANCE:</span>
          <span style={{
            color: '#e72929', margin: '8px auto',
            fontSize: '1.5em'
          }}>
            {moneyFormat(currentBalance)}
          </span>
          {symbolToken}
        </p>
    </div>
  )
}

export default RecibirToken