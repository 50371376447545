/**/import React, { useState, useEffect } from 'react';
import './App.css';
import "@fontsource/poppins";
import 'animate.css';

import Button from '@mui/material/Button';

/* Vistas iniciales */
import AppLogin from "./components/AppLogin";
import AppAuth2 from './components/AppAuth2';

/* Detector de dispositivos */
import { isIOS, isAndroid } from 'react-device-detect';

const Web3 = require("web3");

function AppHome() {
  /* Variables de estado */
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAccount, setWalletAccount] = useState("");
  // RED TOKEN PRUEBA
  //const tokenAddress = "0x3E7d9cdF43c9889CA1578b663ca684C1F1539F64";
  // RED TOKEN
  //const tokenAddress = "0x4313EF7e5A4489A3D13BA9E65717ae7c09Fe64a9"; 
  // RED TOKEN mainnet  ****
  const tokenAddress = "0xE920a57a4E98C88968f50D6fe724FF0Ef1D58D7F"; 
  const stakingAddress = "0x9425475493f62aA0511536E9e8abf9756e5b585A";
  const stakingAddress18 = "0xA74Cb8B63c504547E710347bB3B78286089102C3";
  const stakingAddress24 = "0x58C4cCE6349f829160df96F05B914Ec48aA45Cde";
  // RED LOCAL
  //const tokenAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3";
  //const stakingAddress = "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512";
  //const stakingAddress18 = "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0";
  //const stakingAddress24 = "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9";
  // RED PRUEBA MUMBAY OLD
  //const tokenAddress = "0x7102CB0Fe70704c905379359a7780120Aa34f4E7";
  /*const stakingAddress = "0x94dA22EF2c6D7595CdFDb06fe64347acb0C26bd4";
  const stakingAddress18 = "0xE46835495f80135f87db5C2D553a9d7Df4f00C27";
  const stakingAddress24 = "0x338F77a57594e5E6823679db416B8307C1a4AE03";*/
  // RED PRUEBA MUMBAY NEW
  /*const stakingAddress = "0xaDaf2e20912B2Fe942EC52e042298029451784b1";
  const stakingAddress18 = "0xf7aE0c791014bFB400cCEDa77A7E9DC0E484d795";
  const stakingAddress24 = "0x8924c5bEBb932662FCC5ebe9F9AaddBeE7d7d49e";*/
  // RED PRUEBA MUMBAY V5
  /*const stakingAddress = "0xef83dD49714d06a350C4C777C90e9b80Fc1fB64B";
  const stakingAddress18 = "0xD3e2CE6101484BA666677cB101B9a0EB4693D58F";
  const stakingAddress24 = "0xEA6aeBb27a1DfF46CaeC938203da34F9199A0fB3";*/
  
  
  /* Funcion para revisar conexion con metamask */
  const checkConnection = async() => {
    await window.ethereum
        .request({ method: 'eth_accounts' })
        .then(handleAccountsChanged)
        .catch(console.error);
  };

  /* Funcion para manejar estados y variables de conexion con metamask */
  const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
          setWalletConnected(false);
          setWalletAccount("");
      } else {
          setWalletConnected(true);
          setWalletAccount(accounts[0]);
      }
  };

  /* Funcion de escucha para conexion con metamask */
  useEffect (() => {    
      checkConnection();
  });

  const connectWallet = async () => {
      if(!window.ethereum)
          return;
      
      try {
          await window.ethereum.send('eth_requestAccounts');
          window.web3 = new Web3(window.ethereum);
          window.location.reload();
      } catch (error) {
          return;
      }
  };
  /* Retorno de la vista dependiendo de conexion  */
  return (
    <div className="App">
      {
        !walletConnected ? (
          <AppLogin>
            {
              window.ethereum ?(
                <Button variant="contained" className="btn-landing" size="large" sx={{mt:4}} onClick={ ()=> connectWallet()}>
                  Conectar Wallet
                </Button>
              ) : (
                <Button variant="contained" className="btn-landing" size="large" sx={{mt:4}} target="_blank"
                  href={
                    isIOS ? 'https://apps.apple.com/co/app/coinbase-wallet-nfts-crypto/id1278383455' :
                    isAndroid ? 'https://play.google.com/store/apps/details?id=org.toshi&hl=es_CO&gl=US' : 
                    'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad/related'
                  }
                >
                  Descargar Coinbase Wallet
                </Button>
              )
            }
          </AppLogin>
        ) : (
          <AppAuth2 walletAccount={walletAccount} tokenAddress={tokenAddress} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24} />
        )

      }      
    </div>
  );
}

export default AppHome;
