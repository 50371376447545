import React, {useState, useRef, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SearchIcon from '@mui/icons-material/Search';
import ModalEnviar from './ModalEnviar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import QrReader from "react-qr-reader";
import CardInfo from './CardInfo';

const ERC20Token = require("../../components/ERC20/ERC20Token");
const { web3, applyDecimals } = require("../../utils/ethereumAPI");


const EnviarToken = ({walletAccount, tokenData, tokenAddress, stakingAddress, stakingAddress18, stakingAddress24}) => {

  const [toAddress, setToAddress] = useState("");


  const [tokenData2, setTokenData2] = useState(tokenData);

  useEffect(() => {
    async function fetchData(){
        const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);
        const name = await web3Token.methods.name().call();
        const symbol = await web3Token.methods.symbol().call();
        const totalSupply = await web3Token.methods.totalSupply().call();
        const decimals = await web3Token.methods.decimals().call();

        const accounts = await web3.eth.getAccounts();
        //setAccountAddress(accounts[0]);
        const currentBalance = await web3Token.methods.balanceOf(accounts[0]).call();

        setTokenData2(tokenData2 => [
            tokenData2[0],
            { ...tokenData2[1], value: name},
            { ...tokenData2[2], value: symbol},
            { ...tokenData2[3], value: applyDecimals(totalSupply, decimals)},
            { ...tokenData2[4], value: decimals},
            { ...tokenData2[5], value: applyDecimals(currentBalance, decimals)},
        ])
    }
    fetchData();
}, [tokenAddress]);


  /* QR search file */
  const qrRef = useRef(null);

  const handleChangeAddress = (newAddress) => {
      setToAddress(newAddress);
  };

  const handleScanFile = (result) => {
    if(result){
      setToAddress(result);
    }
  }

  const onScanFile = () => {
    qrRef.current.openImageDialog();
  }

  const handleErrorFile = (error) => {
    console.log(error);
  }

  /* QR web cam */
  const [open, setOpen] = useState(false);
  const [visbleWebCam, setVisbleWebCam] = useState(false);

  const handleClose = () => {
    setVisbleWebCam(false)
    setOpen(false);
  };

  /*const handleWebCamQR = () => {
    console.log("capturar imagen");
    setVisbleWebCam(true)
    setOpen(true);
  }*/


  const handleErrorWebCam = (error) => {
    console.log(error);
  }

  const handleScanWebCam = (result) => {
    if(result){
      setToAddress(result);
      handleClose();
    }
  }

  const pointer = {cursor: 'pointer'}


  return (
    <div>
        <CardInfo walletAccount={walletAccount} tokenData={tokenData2} stakingAddress={stakingAddress} stakingAddress18={stakingAddress18} stakingAddress24={stakingAddress24}/>
        <Grid container justifyContent="center">
        <Box sx={{ display: 'flex', alignItems: 'flex-center', mt: 4  }}>
            <SearchIcon sx={{ color: '#000', mr: 1, my: 3 }} style={pointer} />
            <TextField id="input-with-sx" label="Enter Address or QR code" variant="standard" style={{width: 300}} 
                InputLabelProps={{style: {color: '#000'} }}
                sx={{ input: { color: '#000' } }}
                onChange={(e) => handleChangeAddress(e.target.value)}
                value={toAddress}
            />
            <CropFreeIcon sx={{ color: '#000', ml: 1, my: 3 }} style={pointer} onClick={onScanFile} />
        </Box>
        <br/><br/>
        </Grid>

        <Grid container justifyContent="center">
          <Box sx={{ display: 'flex', alignItems: 'flex-center'  }}>
          <QrReader 
                ref={qrRef}
                delay={300}
                style={{display: 'none'}}
                onError={handleErrorFile}
                onScan={handleScanFile}
                legacyMode 
              />
            <ModalEnviar tokenData={tokenData2} toAddress={toAddress}/>
          </Box>  
            <Dialog open={open} onClose={handleClose} >
              <DialogTitle id="alert-dialog-title" style={{textAlign:"center"}}>
                {"Escanear código QR"}
              </DialogTitle>
              <DialogContent>
                {visbleWebCam && <QrReader 
                  delay={300}
                  style={{width:'100%'}}
                  onError={handleErrorWebCam}
                  onScan={handleScanWebCam}/>
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="error">Cerrar</Button>
              </DialogActions>
            </Dialog>
        </Grid>
    </div>
  )
}

export default EnviarToken