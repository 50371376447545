import React,{ useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import SelectActivo from '../../SelectActivo';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { CircularProgress, Typography } from '@mui/material';

const { web3, applyDecimals, paridad } = require('../../utils/ethereumAPI');
const ERC20Token = require("../ERC20/ERC20Token");

export default function ModalEnviar({tokenData, toAddress}) {

  const [cantidad, setCantidad] = useState("");
  const [usdt, setUsdt] = useState(0);
  
  const [validAddressIcon, setValidAddressIcon] = useState("error");
  const [validAddress, setValidAddress] = useState(false);
  const [validCantidad, setValidCantidad] = useState(false);
  

  const tokenAddress = tokenData.find(x => x.name === "TokenAddress").value;
  const currentBalance = tokenData.find(x => x.name === "Currentbalance").value;
  const symbol = tokenData.find(x => x.name === "Symbol").value;
  
  const updateCantidad = (value) => {
    value === 0 ? setCantidad("") : setCantidad(value); 
    setUsdt(parseFloat((value * paridad).toFixed(4)));
    setValidCantidad((value === 0 || value === "") ? false : true);

  };
  
  const [open, setOpen] = useState(false);

  const [enviar, setEnviar] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageIcon, setMessageIcon] = useState("error");

  web3.givenProvider = web3.currentProvider;
  web3.eth.givenProvider = web3.currentProvider;
  web3.eth.accounts.givenProvider = web3.currentProvider;

  const web3Token = new web3.eth.Contract(ERC20Token.abi, tokenAddress);

  const handleClickOpen = (toAddress) => {
    if (/^0x[0-9a-fA-F]{40}$/.test(toAddress)) {
      setValidAddressIcon("success");
      setValidAddress(true);
    }else{
      setValidAddressIcon("error");
      setValidAddress(false);
    }

    setOpen(true);
    setEnviar(true);
  };

  const handlerSiguiente = () => {
    setEnviar(!enviar);
  };

  const handlerRechazar = () => {
    setEnviar(!enviar);
    setLoading(false);
    setMessage("");
    setMessage("");
    setMessageIcon("error");
    setShowMessage(false);
  };
  

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
      setLoading(false);
      setMessage("");
      setMessageIcon("error");
      setShowMessage(false);
    }, 1);
  };

  const onClickTransfer = async () => {
    setLoading(true);
    try{
        const accounts = await web3.eth.getAccounts();
        const gasPrice = await web3.eth.getGasPrice();

        const amountToSend = applyDecimals(cantidad, 18, "positive");
        await web3Token.methods.transfer(toAddress, amountToSend)
                            .send({ from: accounts[0], gasPrice: gasPrice});
                            
        setMessage(`Transferencia exitosa ${cantidad} RED enviados.`);
        setShowMessage(true);
        setMessageIcon("success");
    }catch(error){
        setMessage("Error "+error.message);
        setMessageIcon("error");
        setShowMessage(true);
    }
    setLoading(false);
}

  const Modal1 = ({toAddress}) => (
    <>
        <DialogTitle>Envío de Tokens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert style={{wordWrap:'break-word', width:'100%'}} severity={validAddressIcon} >
              <Typography
                  variant="body2"
                  sx={{fontSize: 10 }}
                  style={{ wordWrap: "break-word", width:'100%'}}>
                    Wallet destino: {toAddress} {validAddress? "" : "(incorrecta)"}
              </Typography>
              </Alert>
          </DialogContentText>
          <br></br>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                  <SelectActivo />
                </Grid>
                <Grid item xs={5}>
                  <p className='text-balance-inverse'>
                    Balance: {currentBalance} {symbol}<br/>
                    Equivalente: {parseFloat(currentBalance * paridad).toFixed(2)} USDT
                  </p>
                </Grid>
            </Grid>
          </Box>
          

          <br></br>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Cantidad"
                    type="number"
                    fullWidth
                    variant="standard"
                    InputProps={{max: currentBalance, min: 0}}
                    onChange={(e) => {
                      var value = parseInt(e.target.value, 10);
                      if (value > currentBalance) value = currentBalance;
                      if (value < 0) value = 0;
                      if (isNaN(value)) value = 0;
                      updateCantidad(value);
                    }}
                    value={cantidad}
                  />
                </Grid>
                <Grid item xs={5}>
                  <br/>
                  <p className='text-balance-inverse'>Equivalente: {usdt} USDT</p>
                </Grid>
            </Grid>
          </Box>
        
          <br></br>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Cancelar</Button>
          <Button disabled={!validAddress || !validCantidad} onClick={handlerSiguiente} color="primary">Siguiente</Button>
        </DialogActions>
    </>
  )


  const Modal2 = ({toAddress}) => (
    <>
        <DialogTitle>Envío de Tokens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="success" >Wallet destino: {toAddress}</Alert>
          </DialogContentText>
          
            <Grid container className='panel-confirmar' >
                <Grid item xs={12}  >
                  <Chip label="ENVIANDO RED" variant="outlined" sx={{marginTop: 2, }}/> 
                  <h3 className='label-cantidad'>{cantidad}</h3>
                  <br/><br/><br/>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <p className='text-balance-inverse'><b>Total</b><br/><br/></p>   
                </Grid>
                <Grid item xs={6}>
                  <p className='text-balance'>{usdt} USDT<br/><b>{cantidad} RED</b><br/><br/></p>
                </Grid>

                <Grid item xs={12}>
                {showMessage && <Alert  severity={messageIcon} >
                    {message}
                  </Alert>
                }

            </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          {!showMessage ? 
            <><Button onClick={handlerRechazar} color="error">Rechazar</Button>
            <Button 
              onClick={(e) => onClickTransfer()}
              disabled={loading}
              color="success">{loading ? <CircularProgress size={25} /> : ""} 
              Confirmar
            </Button></> :
            <Button onClick={handleClose} color="success">Cerrar</Button>
          }
        </DialogActions>
    </>
  )

  return (
    <>
    <Dialog open={open} onClose={handleClose}>
      {enviar ? <Modal1 toAddress={toAddress}/> : <Modal2 toAddress={toAddress} /> }
    </Dialog>
    <Button variant="contained" onClick={(e) =>handleClickOpen(toAddress)} 
      style={{
        color: '#FFFFFF',
        borderWidth: '0px',
        fontFamily: 'Poppins',
        backgroundColor: '#7f0215'
      }} >Enviar</Button>
    </>
  );
}