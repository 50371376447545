//import WalletConnectProvider from "@walletconnect/web3-provider";
const Web3 = require("web3");

//  Create WalletConnect Provider
/*const provider = new WalletConnectProvider({
    rpc: {
        1: "https://mainnet.mycustomnode.com",
        3: "https://ropsten.mycustomnode.com",
        100: "https://dai.poa.network",
        // ...
      },
  });*/
  
//  Enable session (triggers QR Code modal)
/*const activateProvider = async () => {
    try{
        await provider.enable();        
    }catch(error){
        console.log("ERROR: Activation provider");
    }
}*/
//activateProvider();

const web3 = new Web3(window.ethereum);
const paridad = 0.1;
//const web3 = new Web3(provider);


const Decimal = require('decimal.js');
const applyDecimals = (rawValue, decimals, sign = "negative") => {
    if(!rawValue)
        return "";
    
    return Decimal(rawValue).mul(Decimal(10).pow(Decimal(sign === "positive" ? decimals : -decimals))).toFixed();

}

module.exports = {
    applyDecimals,
    web3,
    paridad
}